<template>
 <div class="limiter">
   
        <div class="container-login100">
          <div class="wrap-login100 p-t-50 p-b-90">
            <form class="login100-form validate-form flex-sb flex-w" @submit.prevent="forgetPassword">
              <span class="login100-form-title p-b-51">
                Восстановление пароля
              </span>

              
              <div class="wrap-input100 validate-input m-b-16" data-validate = "Username is required">
                <input class="input100" type="email" name="username" placeholder="E-mail" v-model="user.email" />
                <span class="focus-input100"></span>
              </div>
             
              <div class="container-login100-form-btn m-t-17">
                <button @click="userLogin" class="login100-form-btn">
                  Восстановить
                </button>

              </div>


               <div class="flex-sb-m w-full p-t-24 p-b-24">
                  <router-link :to="{name: 'login'}">Войти</router-link>
              </div>

            </form>
          </div>
        </div>
      </div>
      
</template>

<script>
import firebase from "firebase";

export default {
  data() {
    return {
      user: {   
        email: ''
      }
    };
  },
   created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        this.$router.push('/home');
      } 
    });
  },
  methods: {
    forgetPassword() {
        firebase
        .auth()
        .sendPasswordResetEmail(this.user.email)
        .then(() => {
            alert('Check your registered email to reset the password!')
            this.user = {   
              email: ''
            }
        }).catch((error) => {
          alert(error)
        })
    }
  }
};
</script>